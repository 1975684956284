<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 기계분류 -->
          <c-select
            type="search"
            codeGroupCd="HHM_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="machineryClassification"
            label="기계분류"
            v-model="searchParam.machineryClassification"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 담당부서 -->
          <c-dept
            type="search"
            label="담당부서"
            name="departmentDeptCd"
            v-model="searchParam.departmentDeptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 담당자 -->
          <c-field
            type="user"
            label="담당자"
            name="departmentUserId"
            v-model="searchParam.departmentUserId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 등급 -->
          <c-select
            type="search"
            codeGroupCd="HAZARD_GRADE_CD"
            itemText="codeName"
            itemValue="code"
            name="gradeCd"
            label="등급"
            v-model="searchParam.gradeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 검사결과 -->
          <c-select
            type="search"
            codeGroupCd="HAZARD_RESULT_CD"
            itemText="codeName"
            itemValue="code"
            name="resultCd"
            label="검사결과"
            v-model="searchParam.resultCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 검사대상 여부 -->
          <c-select
            type="search"
            itemText="codeName"
            itemValue="code"
            :comboItems="targetCdItems"
            :editable="editable"
            label="검사대상 여부"
            name="inspectionSubjectFlag"
            v-model="searchParam.inspectionSubjectFlag">
          </c-select>
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            codeGroupCd="MDM_CHECK_KIND_CD"
            itemText="codeName"
            itemValue="code"
            :editable="editable"
            label="검사종류"
            name="inspectionKindCd"
            v-model="searchParam.inspectionKindCd">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :comboItems="cycleCdItems"
            :editable="editable"
            type="search"
            itemText="codeName"
            itemValue="code"
            label="검사주기"
            name="inspectionCycle"
            v-model="searchParam.inspectionCycle">
          </c-select>
        </div> -->
        <!-- 설치년월 -->
        <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            :range="true"
            type="month"
            name="period"
            label="설치년월"
            v-model="period"
          ></c-datepicker>
        </div> -->
        <!-- <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
          <c-multi-select
            :editable="editable"
            :comboItems="rawItems"
            itemText="codeName"
            itemValue="code"
            label="관련 법규"
            name="relatedLawList"
            maxValues="3"
            v-model="searchParam.relatedLawList">
          </c-multi-select>
        </div> -->
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="유해위험기계기구 목록"
      tableId="equipmentInsp"
      :columns="grid.columns"
      :data="grid.data"
      selection="multiple"
      :checkClickFlag="false"
      rowKey="hhmHazardousMachineryId"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group>
            <c-btn label="검사결과 일괄 업로드" :editable="editable" icon="assignment" />
            <c-btn label="신규등록" :editable="editable" icon="add" @btnClicked="onItemClick" />
            <c-btn label="검색" icon="search" @btnClicked="getList" />
            <c-btn label="삭제" :editable="editable" icon="delete_forever" @btnClicked="remove" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: "equipment-inspection",
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantNm",
            field: "plantNm",
            label: "사업장",
            align: "center",
            sortable: true,
          },
          {
            name: "machineryName",
            field: "machineryName",
            label: "기계명",
            align: "center",
            sortable: true,
            type: "link",
          },
          {
            name: "itemNo",
            field: "itemNo",
            label: "item 번호",
            align: "center",
            sortable: true,
          },
          {
            name: "machineryClassificationName",
            field: "machineryClassificationName",
            label: "기계분류",
            align: "center",
            sortable: true,
          },
          {
            name: "amount",
            field: "amount",
            label: "수량",
            align: "center",
            sortable: true,
          },
          {
            name: "departmentDeptName",
            field: "departmentDeptName",
            label: "담당부서",
            align: "center",
            sortable: true,
          },
          {
            name: "departmentUserName",
            field: "departmentUserName",
            label: "담당자",
            align: "center",
            sortable: true,
          },
          {
            name: "resultCd",
            field: "resultCd",
            label: "검사결과",
            type: 'tag',
            colorItems: [
              { stepperMstCd: 'HRC000005', stepperMstNm: '합격', colorClass: 'blue' },
              { stepperMstCd: 'HRC000010', stepperMstNm: '불합격', colorClass: 'red' },
            ],
            align: "center",
            sortable: true,
          },
          {
            name: "gradeName",
            field: "gradeName",
            label: "등급",
            align: "center",
            sortable: true,
          },
          {
            name: "inspectionSubjectFlag",
            field: "inspectionSubjectFlag",
            label: "검사대상",
            type: 'tag',
            colorItems: [
              { stepperMstCd: 'Y', stepperMstNm: '대상', colorClass: 'green' },
              { stepperMstCd: 'N', stepperMstNm: '비대상', colorClass: 'orange' },
            ],
            align: "center",
            sortable: true,
          },
          {
            name: "inspectionKindName",
            field: "inspectionKindName",
            label: "검사종류",
            align: "center",
            sortable: true,
          },
          {
            name: "inspectionCycleName",
            field: "inspectionCycleName",
            label: "검사주기",
            align: "center",
            sortable: true,
          },
          {
            name: "emplacementMonth",
            field: "emplacementMonth",
            label: "설치년월",
            align: "center",
            sortable: true,
          },
          {
            name: "lastInspectionDate",
            field: "lastInspectionDate",
            label: "최근검사일자",
            align: "center",
            sortable: true,
          },
          {
            name: "nextInspectionDate",
            field: "nextInspectionDate",
            label: "차기검사일자",
            align: "center",
            sortable: true,
          },
          {
            name: "inspCertificateIssuanceDate",
            field: "inspCertificateIssuanceDate",
            label: "검사증교부일자",
            align: "center",
            sortable: true,
          },
          {
            name: 'relatedLawsName',
            field: 'relatedLawsName',
            label: '관련법규',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        machineryClassification: null,
        // relatedLawList: [],
        inspectionSubjectFlag: null,
        resultCd: null,
        gradeCd: null,
        inspectionKindCd: null,
        inspectionCycle: null,
        departmentDeptCd: '',
        departmentUserId: '',
        startYmd: '',
        endYmd: '',
      },
      period: ['', ''],
      editable: true,
      targetCdItems: [
        { code: "Y", codeName: "대상" },
        { code: "N", codeName: "비대상" },
      ], // 대상여부
      listUrl: '',
      deleteUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      // url setting
      this.listUrl = selectConfig.sop.hhm.hazard.check.list.url;
      this.deleteUrl = transactionConfig.sop.hhm.hazard.check.delete.url;
      // list setting
      this.getList();
    },
    getList() {
      this.searchParam.startYmd = this.period[0];
      this.searchParam.endYmd = this.period[1];
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = "유해위험기계기구 상세"; // 유해위험기계기구 상세
      this.popupOptions.param = {
        hhmHazardousMachineryId: row.hhmHazardousMachineryId ? row.hhmHazardousMachineryId : '',
      };
      this.popupOptions.target = () =>
        import(`${"./hazardEquipmentClassDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.height = '300px';
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    onItemClick() {
      this.popupOptions.target = () =>
      import(`${"./hazardEquipmentClassDetail.vue"}`);
      this.popupOptions.isFull = true;
      this.popupOptions.title = '유해위험기계기구 상세'; // 유해위험기계기구 상세
      this.popupOptions.param = {
        hhmHazardousMachineryId: '',
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    remove() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request((_result) => {
            this.$_.forEach(selectData, item => {
              this.grid.data = this.$_.reject(this.grid.data, { hhmHazardousMachineryId: item.hhmHazardousMachineryId })
            })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.getList();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
  },
};
</script>
